import React from "react";
import { getChartSegmentTotal } from "./ChartComponent";
import { Grid } from '@material-ui/core';
import BarChart from "components/D3Charts/BarChart";

export const ShipmentProcessingChartBuilder = (props) => {

  const { margins, height, type, subtype, chartsData, keys, setDetails, setHeaderTotalCount } = props

  const xKey = "stateDesc";

  const columns = [2, 3, 4];

  const barData = chartsData?.length > 5 ? chartsData.filter((d, i) => columns.includes(i)) : []

  setHeaderTotalCount(getChartSegmentTotal({keys,data:barData}))

  return (
    <Grid item xs={12}><BarChart id={`${subtype}_shipmentProcess`} wrapit data={barData} keys={keys} type={type} subtype={subtype} setDetails={setDetails} xKey={xKey} margins={margins} height={height} /></Grid>
  );
}
