import React from 'react';
import moment from "moment-timezone";
import CustomCellRender from "components/common/CustomCellRender";

export const laoOrderDetailsColumns = {
  columnOrder: [
    {
      title: 'order_#', field: "orderNum",
      render: rowData => {
        return <CustomCellRender id="orderNum" value={rowData.orderNum} />
      }
    },
    {
      title: 'order_type', field: "orderType",
      render: rowData => {
        return <CustomCellRender id="orderType" value={rowData.orderType} />
      }
    },
    {
      title: 'purchase_order_trade_PO', field: "ftzPoNum",
      render: rowData => {
        return <CustomCellRender id="ftzPoNum" value={rowData.ftzPoNum} />
      }
    },
    {
      title: 'purchase_order_trade_SO', field: "ftzSoNum",
      render: rowData => {
        return <CustomCellRender id="ftzSoNum" value={rowData.ftzSoNum} />
      }
    },
    {
      title: 'invoice_num', field: "invoiceNum",
      render: rowData => {
        return <CustomCellRender id="invoiceNum" value={rowData.invoiceNum} />
      }
    },
    {
      title: 'invoicing_date', field: "invoiceDate",
      render: rowData => {
        return <CustomCellRender id="invoiceDate" value={rowData.invoiceDate} />
      }
    },
    {
      title: 'vendor_number', field: "vendorNumber",
      render: rowData => {
        return <CustomCellRender id="vendorNumber" value={rowData.vendorNumber} />
      }
    },
    {
      title: 'vendor_plant', field: "orderOriginId",
      render: rowData => {
        return <CustomCellRender id="orderOriginId" value={rowData.orderOrigin.id} />
      }
    },
    {
      title: 'free_trade_destination_plant', field: "ftzOrderDestinationId",
      render: rowData => {
        return <CustomCellRender id="ftzOrderDestinationId" value={rowData.ftzOrderDestination.id} />
      }
    },
    {
      title: 'free_trade_destination_description', field: "ftzOrderDestinationName",
      render: rowData => {
        return <CustomCellRender id="ftzOrderDestinationName" value={rowData.ftzOrderDestination.name} />
      }
    },
    {
      title: 'destination_plant', field: "finalOrderDestinationId",
      render: rowData => {
        return <CustomCellRender id="finalOrderDestinationId" value={rowData.finalOrderDestination.id} />
      }
    },
    {
      title: 'destination_description', field: "finalOrderDestinationName",
      render: rowData => {
        return <CustomCellRender id="finalOrderDestinationName" value={rowData.finalOrderDestination.name} />
      }
    },
    {
      title: 'origin_port', field: "exportOriginPort",
      render: rowData => {
        return <CustomCellRender id="exportOriginPort" value={rowData.exportOriginPort} />
      }
    },
    {
      title: 'international_shipper', field: "exportInternationalShipper",
      render: rowData => {
        return <CustomCellRender id="exportInternationalShipper" value={rowData.exportInternationalShipper} />
      }
    },
    {
      title: 'booking_number', field: "exportBookingNumber",
      render: rowData => {
        return <CustomCellRender id="exportBookingNumber" value={rowData.exportBookingNumber} />
      }
    },
    {
      title: 'container_id', field: "exportContainerId",
      render: rowData => {
        return <CustomCellRender id="exportContainerId" value={rowData.exportContainerId} />
      }
    },
    {
      title: 'shipment_number', field: "exportShipmentNumber",
      render: rowData => {
        return <CustomCellRender id="exportShipmentNumber" value={rowData.exportShipmentNumber} />
      }
    },
    {
      title: 'load_number', field: "shipmentNum",
      render: rowData => {
        return <CustomCellRender id="shipmentNum" value={rowData.shipmentNum} />
      }
    },
    {
      title: 'delivery_number', field: "deliveryNum",
      render: rowData => {
        return <CustomCellRender id="deliveryNum" value={rowData.deliveryNum} />
      }
    },
    {
      title: 'export_shipment_status', field: "exportShipmentStatus",
      render: rowData => {
        return <CustomCellRender id="exportShipmentStatus" value={rowData.exportShipmentStatus} />
      }
    },
    {
      title: 'origin_country', field: "orderOriginCountry",
      render: rowData => {
        return <CustomCellRender id="orderOriginCountry" value={rowData.orderOrigin.country} />
      }
    },
    {
      title: 'destination_country', field: "finalOrderDestinationCountry",
      render: rowData => {
        return <CustomCellRender id="finalOrderDestinationCountry" value={rowData.finalOrderDestination.country} />
      }
    },
    {
      title: 'bill_lading', field: "exportBillOfLading",
      render: rowData => {
        return <CustomCellRender id="exportBillOfLading" value={rowData.exportBillOfLading} />
      }
    },
    {
      title: 'bid_lane_number', field: "exportBidLaneNumber",
      render: rowData => {
        return <CustomCellRender id="exportBidLaneNumber" value={rowData.exportBidLaneNumber} />
      }
    },
    {
      title: 'export_shipment_etd_date', field: "exportShipmentETD",
      render: rowData => {
        return <CustomCellRender id="exportShipmentETD" value={rowData.exportShipmentETD} />
      }
    },
    {
      title: 'export_shipment_eta_date', field: "exportShipmentETA",
      render: rowData => {
        return <CustomCellRender id="exportShipmentETA" value={rowData.exportShipmentETA} />
      }
    },
    {
      title: 'export_kc_documental_cutoff_date', field: "exportKcDocumentalCutOff",
      render: rowData => {
        return <CustomCellRender id="exportKcDocumentalCutOff" value={rowData.exportKcDocumentalCutOff} />
      }
    }
  ],
  columnConfiguration: (d) => {
    return {
      invoiceDate: d?.invoiceDate ? moment(d.invoiceDate).format(`DD-MM-YYYY`) : '-',
      orderOriginId: d?.orderOrigin?.id ? d.orderOrigin.id : '-',
      ftzOrderDestinationId: d?.ftzOrderDestination?.id ? d.ftzOrderDestination.id : '-',
      ftzOrderDestinationName: d?.ftzOrderDestination?.name ? d.ftzOrderDestination.name : '-',
      finalOrderDestinationId: d?.finalOrderDestination?.id ? d.finalOrderDestination.id : '-',
      finalOrderDestinationName: d?.finalOrderDestination?.name ? d.finalOrderDestination.name : '-',
      orderOriginCountry: d?.orderOrigin?.country ? d.orderOrigin.country : '-',
      finalOrderDestinationCountry: d?.finalOrderDestination?.country ? d.finalOrderDestination.country : '-',
      exportShipmentETD: d?.exportShipmentETD ? moment(d.exportShipmentETD).format(`DD-MM-YYYY`) : '-',
      exportShipmentETA: d?.exportShipmentETA ? moment(d.exportShipmentETA).format(`DD-MM-YYYY`) : '-',
      exportKcDocumentalCutOff: d?.exportKcDocumentalCutOff ? moment(d.exportKcDocumentalCutOff).format(`DD-MM-YYYY`) : '-',
    }
  }
};


export const laoExpandDetailsColumns = {
  columnOrder: [
    { title: 'po_item', field: 'itemPosNum', emptyValue: "-" },
    { title: 'sku_material', field: 'materialNum', emptyValue: "-" },
    { title: 'material_description', field: 'itemDesc', emptyValue: "-" },
    { title: 'material_type', field: 'materialType', emptyValue: "-" },
    { title: 'po_quantity', field: 'orderQty', emptyValue: "-" },
    { title: 'quantity_packed', field: 'packedQty', emptyValue: "-" },
    { title: 'volume_packed', field: 'packedQtyVolume', emptyValue: "-" },
    // {
    //   title: 'volume_packed', field: 'packedQtyVolume', emptyValue: "-",
    //   render: rowData => {
    //     return <CustomCellRender id="packedQtyVolume" value={rowData.packedQtyVolume * rowData.packedQty} />
    //   }
    // },
    { title: 'order_unit', field: 'qtyUom', emptyValue: "-" },
  ],
  columnConfiguration: (d) => {
    return {
      // Keeping this intact in case of future conversions of field

      // itemPosNum: get(d, "itemPosNum", "-"),
      // materialNum: get(d, "materialNum", "-"),
      // itemDesc: get(d, "itemDesc", "-"),
      // orderQty: get(d, "orderQty", "-"),
      // packedQty: get(d, "packedQty", "-"),
      // packedQtyVolume: d?.packedQtyVolume * d?.packedQty
      // qtyUom: get(d, "qtyUom", "-"),
    }
  }
}
