import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { RefreshOutlined } from '@material-ui/icons';
import ExportVolumeChart from './Charts/ExportVolumeChart';
import { useTranslation } from "react-i18next"
import { useState } from 'react';
import { laoGetCharts } from 'reduxLib/services/LaoServices/laoChartService';
import { useDispatch, useSelector } from 'react-redux';
import useSetQuery, { useMultiSetQuery } from 'customHooks/useQuery';
import { useDeepCompareEffect } from 'react-use';
import { useLAOGlobalContext } from 'context/LAOGlobalContext';
import moment from "moment-timezone";
import esLocale from "moment/locale/es"; // Do not remove these imports needed for chart translation 
import ptLocale from "moment/locale/pt"; // Do not remove these imports needed for chart translation 

const useStyles = makeStyles(theme => ({
  chartContainer: {
    background: theme.palette.primary.base,
    borderRadius: theme.spacing(1.5),
    padding: `${theme.spacing(3)}px ${theme.spacing(8)}px ${theme.spacing(3)}px ${theme.spacing(8)}px`,
    gap: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(3)}px`,
    }
  },
  spining: {
    pointerEvents: "none",
    height: '0.9em',
    animation: "App-logo-spin infinite 1s linear !important"
  },
  spin: {
    height: '0.9em',
    cursor: "pointer",
  },
  chartCard: {
    maxWidth: '100%',
    minWidth: '100%'
  },
  active: {
    opacity: "100%",
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center'
    }
  },
  faded: {
    opacity: "30%",
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center'
    }
  },
  weeklyFaded:{
    opacity: "30%",
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  },
  bluesquare: {
    height: 12,
    width: 12,
    backgroundColor: theme.palette.chartColors.healthy,
    display: 'inline-block'
  },
  textContent: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    width: 'max-content',
    display: 'inline-block'
  },
  volume: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-evenly'
    }
  },
  frequency: {
    display: 'flex',
    justifyContent: 'end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between'
    }
  },
  daily: {
    display: 'flex',
    justifyContent: 'end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  },
  weeklyReload: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  },
  reload: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-end'
    }
  },
  loadingWindow: {
    height: '36vh'
  }
}));

const LAOVolumeChart = ({ ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [{ chartType }, setParams] = useSetQuery();
  const setMultiQuery = useMultiSetQuery();
  const chartTypes = chartType?.split("_")
  const [activeVolume, setActiveVolume] = useState({
    type: chartTypes?.[0] || "export",
    subtype: chartTypes?.[1] || "daily"
  });

  const laoChartsData = useSelector(({ laoCharts }) => laoCharts);
  const [chartData, setChartData] = useState([]);
  const isMobileLao = useMediaQuery(useTheme().breakpoints.down('xs'));

  const {
    locations,
    BU,
    language
  } = useLAOGlobalContext();
  moment.locale(language);

  const subtypes = {
    export: {
      daily: "EXPORT_INVOICE_COUNT_DAILY"
    },
    import: {
      daily: "IMPORT_ORDER_COUNT_DAILY",
      weekly: "IMPORT_ORDER_COUNT_WEEKLY"
    }
  };
  const subtype = subtypes[activeVolume.type][activeVolume.subtype];

  const chartProps = {
    showTotal: true,
    yLabel: '',
    excludedFilters: {},
    height: 300,
    data: chartData,
    startDate: 'startDate',
    xKey: 'formattedStarDate',
    keys: ['totalCountSTO'],
    yKey: 'totalCountSTO',
    themeType: 'dark',
    loading: false,
    ...rest
  };

  const setDetails = (data) => {
    let dateFilter = chartTypes?.[0] === "export"
      ? {
        invoiceDateRange: {
          startTime: data?.startDate,
          endTime: data?.endDate
        }
      }
      : {
        importEtaRange: {
          startTime: data?.startDate,
          endTime: data?.endDate
        }
      }

    setMultiQuery({
      laoFilters: JSON.stringify(dateFilter),
      tableType: chartTypes?.[0] === 'export' ? "exportOrderDetails" : "importOrderDetails",
      isHyperlink: true
    });
  }

  const getCharts = () => {
    const controller = new AbortController();
    dispatch(laoGetCharts({
      controller,
      subtype,
      body: {
        businessUnits: BU,
        [activeVolume.type === "export" ? "originSites" : "destSites"]: locations,
        startDate: new Date().toISOString().split("T")[0]
      }
    }));
  }

  const setChartTypes = (values) => {
    const updatedChartTypes = {
      ...activeVolume,
      ...values
    };
    setActiveVolume(updatedChartTypes);
    setParams({
      key: "chartType",
      value: `${updatedChartTypes.type}_${updatedChartTypes.subtype}`
    });
  }

  useEffect(() => {
    const data = laoChartsData[subtype];
    if (data?.status?.status === "success" && data?.data?.length) {
      let processedData = data?.data?.map(d => {
        return {
          ...d,
          formattedStarDate: moment(d[chartProps.startDate], "YYYY-MM-DD").format("DD/MM ddd")
        }
      });
      setChartData(processedData);
    } else {
      setChartData([])
    }
  }, [laoChartsData[subtype]]);

  useDeepCompareEffect(() => {
    getCharts();
  }, [BU, locations, activeVolume, language]);

  return (
    <Grid container className={classes.chartContainer} data-testid="lao-chartcontainer">
      <Grid container item spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={5} className={classes.volume}>
          <Grid item sm={12} md={6} lg={language === 'en' ? 3 : 6}>
            <Typography data-testid="exportVolume" variant="h6" className={activeVolume.type === "export" ? classes.active : classes.faded}
              onClick={() => setChartTypes({ type: 'export', subtype: "daily" })}>{isMobileLao ? t('exportOrderMobile') : t('exportVolume')}</Typography>
          </Grid>
          <Grid item sm={12} md={6} lg={language === 'en' ? 3 : 6}>
            <Typography data-testid="importVolume" variant="h6" className={activeVolume.type === "import" ? classes.active : classes.faded}
              onClick={() => setChartTypes({ type: 'import', subtype: "daily" })}>{isMobileLao ? t('importOrderMobile') : t('importVolume')}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={7} className={classes.frequency}>
          <Grid item sm={3} md={5} lg={2} className={classes.daily}>
            <Typography variant="h6">
              <Radio
                checked={activeVolume.subtype === "daily"}
                onClick={() => setChartTypes({ subtype: 'daily' })}
                inputProps={{ 'data-testid': 'radio_daily' }}
              />
              {t('daily')}
            </Typography>
          </Grid>
          <Grid item sm={language === 'en' ? 3 : 4} md={5} lg={language === 'en' ? 2 : 3} className={classes.weeklyReload}>
            <Typography
              variant="h6"
              className={activeVolume.type === "export" ? classes.weeklyFaded : ""}>
              <Radio
                checked={activeVolume.subtype === "weekly"}
                disabled={activeVolume.type === "export"}
                onClick={() => setChartTypes({ subtype: 'weekly' })}
                inputProps={{ 'data-testid': 'radio_weekly' }}
              />
              {t('weekly')}
            </Typography>
          </Grid>
          <Grid item sm={3} md={2} lg={1} className={classes.reload}>
            <IconButton onClick={getCharts} data-testid="refreshbtn" >
              <RefreshOutlined fontSize='small' className={laoChartsData?.loading ? classes.spining : classes.spin}/>
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid container sm={12} md={12} lg={12}>
        <div className={classes.chartCard}>
          {
            laoChartsData?.loading
              ?
              <div className={classes.loadingWindow}>
                <Typography color="primary">
                  {t("please_wait")}
                </Typography>
              </div>
              :
              < ExportVolumeChart {...chartProps} setDetails={setDetails} />
          }
        </div>
        {activeVolume.type === "import" && <div>
          <div className={classes.bluesquare} />
          <div className={classes.textContent}>STO</div>
        </div>}
      </Grid>
    </Grid>
  );
};

export default LAOVolumeChart;
