import React from "react";
import { Grid } from '@material-ui/core';
import PieChart from "components/D3Charts/PieChart";
import { sum } from "d3"
import { useTranslation } from "react-i18next";

export const CarrierCommittedChartBuilder = (props) => {

  const { margins, height, type, subtype, chartsData, setDetails, setHeaderTotalCount } = props
  const { t } = useTranslation();
  const xKey = "stateDesc";
  const pie3Data = chartsData?.length > 5 ? [
    {
      name: chartsData[5][xKey],
      value: chartsData[5].redCount,
      color: 'RED',
      state: chartsData[5].state
    },
    {
      name: chartsData[5][xKey],
      value: chartsData[5].blueCount,
      color: 'BLUE',
      state: chartsData[5].state
    },
  ] : [];

  setHeaderTotalCount(sum(pie3Data, d => d.value))

  return (
    <Grid item xs={12} data-testid='carrier_committed'><PieChart center={t("loads")} footer={t("tender_accepted")} header={t("carrier_committed")} xKey={"color"} data={pie3Data} margins={margins} height={height} type={type} subtype={subtype} setDetails={setDetails} /></Grid>
  );
}
