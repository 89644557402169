import React from 'react';
import { distributionChannel } from 'configs/appConstants';
import { Box, FormControlLabel, Grid, makeStyles, RadioGroup, Typography, FormControl, FormGroup, Accordion, AccordionSummary, AccordionDetails, useMediaQuery, useTheme } from '@material-ui/core';
import KCCheckBox from "components/common/Elements/KCCheckbox";
import KCRadio from 'components/common/Elements/KCRadio';
import { filterStyles } from 'theme';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

const useStyles = makeStyles(filterStyles)


export default (props) => {
    const { handleChange, expanded } = props
    const classes = useStyles()
    const { t } = useTranslation();
    const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"))
    const { saleOrgsCheckboxArray, filterViewTypeEnum, filterViewType, customerFiltersHandlers, selectedDC } = props
    return (
        <Grid className={classes.customerFilterRoot} item xs={12} sm={12} md={4} lg={3}>
        <Accordion className={classes.accordian} disabled={!isMobile} expanded={ isMobile ? expanded === 'leftpanel' : true} onChange={handleChange('leftpanel')}>
            <AccordionSummary
                expandIcon={isMobile && <ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.accordianSummary}
            >
                <Typography variant='body1' className={classes.underline}>{t('filter_by')}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordianDetails}>
                <Box mt={1} mb={1} >
                    <Typography variant="h6" >{t('view_by')}</Typography>
                    <FormControl component="fieldset">
                        <RadioGroup row aria-label="position" defaultValue="end" name="searchType" value={filterViewType} onChange={customerFiltersHandlers.searchType.handleChange}>
                            <FormControlLabel className={classes.inputGroup} value={filterViewTypeEnum.customer} control={<KCRadio data-testid="viewbyRadioCustomer" />}
                                label={<Typography className={clsx(classes.inputGroup, classes.button)}> {t('customer')} </Typography>}
                            />
                            <FormControlLabel className={classes.inputGroup} value={filterViewTypeEnum.salesOfc} control={<KCRadio data-testid="viewbyRadioSalesOffice" />}
                                label={<Typography className={clsx(classes.inputGroup, classes.button)}> {t('sales_office')} </Typography>}
                            />
                        </RadioGroup>
                    </FormControl>
                </Box>
                <Box mt={1} mb={1} >
                    <Typography variant="h6" >{t('sales_org')}</Typography>
                    <FormControl component="fieldset">
                        <FormGroup>
                            {
                                saleOrgsCheckboxArray.map((salesOrg, index) => {
                                    return (<FormControlLabel
                                        key={index}
                                        className={classes.label}
                                        control={<KCCheckBox name={salesOrg.value} className={classes.inputGroup} checked={salesOrg.checked} data-testid={"salesOrgcheckbox_"+index} />}
                                        onChange={()=>customerFiltersHandlers.selectedSalesOrg.handleChange(salesOrg.value)}
                                        label={<Typography className={clsx(classes.inputGroup, classes.button)}> {salesOrg.value} - {salesOrg.name} </Typography>}
                                    />)
                                })
                            }
                        </FormGroup>
                    </FormControl>
                </Box>
                <Box mt={1} mb={1} >
                    <Typography variant="h6" >{t('distribution_channel')}</Typography>
                    <FormControl component="fieldset">
                        <RadioGroup row aria-label="position" defaultValue="end" name="selectedDC" value={selectedDC} onChange={customerFiltersHandlers.distributionChannel.handleChange}>
                            { distributionChannel.map(({name,value}, index) => 
                                <FormControlLabel key={"distChannel"+index} className={classes.inputGroup} value={value} control={<KCRadio data-testid={"distChannelRadio_"+index} />}
                                label={<Typography className={clsx(classes.inputGroup, classes.button)}>{`${value} - ${t(name)}`}</Typography>}
                            />)}
                        </RadioGroup>
                    </FormControl>
                </Box>
            </AccordionDetails>
        </Accordion>
        </Grid>
    ) 
}