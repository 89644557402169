import React from 'react';
import {
    makeStyles,
    ClickAwayListener,
    Button
} from '@material-ui/core';
import { ExpandMore, ExpandLess } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
    root: {
        position: 'relative'
    }
}));

const SelfAlertDropMenu = ({ children, title }) => {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    return (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            <div className={classes.root} data-testid='alert-filter'>
                <Button
                    data-testid='filter-button'
                    variant='outlined'
                    size="small"
                    disableRipple
                    endIcon={!open ? <ExpandMore /> : <ExpandLess />}
                    onClick={handleClick}
                    color="primary">
                    {title}
                </Button>
                {
                    open && children
                }
            </div>
        </ClickAwayListener>
    );
};

export default SelfAlertDropMenu;
