
const laoOrderDetailsFilters = {
  searchStringList: {
    type: "text",
    stringToArray: true,
    name: "search",
    data: null,
    region: 'lao'
  },
  vendorNumberList: { // Vendor Number
    type: "text",
    name: "vendor_number",
    stringToArray: true, // Multi-search field
    data: null,
    region: 'lao',
    helperText: "helperText"
  },
  vendorPlantList: { // Vendor Plant
    type: "text",
    name: "vendor_plant",
    stringToArray: true,
    data: null,
    region: 'lao',
    helperText: "helperText"
  },
  finalOrderDestinationIdList: { // Customer Code/Plant
    type: "text",
    name: "customer_code_plant",
    stringToArray: true,
    data: null,
    region: 'lao',
    helperText: "helperText"
  },
  finalOrderDestinationNameWithSeparator: { // Customer Name
    type: "text",
    name: "customer_name",
    stringToArray: false,
    stringValue: true, // for chips UI
    separator: /;/,
    data: null,
    region: 'lao',
    helperText: "helperTextWithSeparator"
  },
  exportInternationalShipperWithSeparator: { // International Shipper
    type: "text",
    name: "international_shipper",
    stringToArray: false,
    stringValue: true, // for chips UI
    separator: /;/,
    data: null,
    region: 'lao',
    helperText: "helperTextWithSeparator"
  },
  exportContainerIdList: { // Container ID
    type: "text",
    name: "container_id",
    stringToArray: true,
    data: null,
    region: 'lao',
    helperText: "helperText"
  },
  materialNumList: { // SKU (Material)
    type: "text",
    name: "sku_id",
    stringToArray: true,
    data: null,
    region: 'lao',
    helperText: "helperText"
  },
  itemDescWithSeparator: { // Material Description
    type: "text",
    name: "material_description",
    stringToArray: false,
    stringValue: true, // for chips UI 
    separator: /;/,
    data: null,
    region: 'lao',
    helperText: "helperTextWithSeparator"
  },
  materialTypeList: { // Material Type
    type: "text",
    name: "material_type",
    stringToArray: true,
    data: null,
    region: 'lao',
    helperText: "helperText"
  },
  orderOriginCountryList: { // Origin Country
    type: "text",
    name: "origin_country",
    stringToArray: true,
    data: null,
    region: 'lao',
    helperText: "helperText"
  },
  finalOrderDestinationCountryList: { // Destination Country
    type: "text",
    name: "destination_country",
    stringToArray: true,
    data: null,
    region: 'lao',
    helperText: "helperText"
  },
  exportBillOfLadingList: { // Bill of Lading
    type: "text",
    name: "bill_lading",
    stringToArray: true,
    data: null,
    region: 'lao',
    helperText: "helperText"
  },
  invoiceDateRange: { // Invoicing Date
    type: "date",
    name: "invoicing_date",
    shortName: 'Invoice Date',
    data: null
  },
  exportShipmentETDRange: { // KC ETD
    type: "date",
    name: "export_shipment_etd_date",
    shortName: 'KC ETD',
    data: null
  },
  exportShipmentETARange: { // KC ETA
    type: "date",
    name: "export_shipment_eta_date",
    shortName: 'KC ETA',
    data: null
  },
  exportKcDocumentalCutOffRange: { // Documental Cut Off
    type: "date",
    name: "export_kc_documental_cutoff_date",
    shortName: 'Documental Cut Off',
    data: null
  },
  orderTypes: {
    type: "checkbox",
    name: "order_type",
    data: [
        { name: "po", value: "PO", checked: false },
        { name: "sto", value: "SO", checked: false },
    ]
},
};

export default laoOrderDetailsFilters;