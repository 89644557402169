export const TRANSLATIONS_EN = {
    "network": "Network",
    "stock_constraint_report_title": "Stock Constraint Report",
    "plant": "Plant",
    "load_date": "LOAD DATE",
    "search": "Search",
    "shipment": "Shipment",
    "material": "Material",
    "suggested_inbound_shipments": "Suggested Inbound shipments",
    "recommended_inbound_details": "Recommended Inbound Details",
    "outbound_title": "Outbound",
    "inbound_title": "Inbound",
    "load_start_date_time": "Load Start date & time",
    "incoming_stock": "Incoming Stock",
    "unrestricted_quantity": "Unrestricted Quantity",
    "demand_quantity": "Demand Quantity",
    "no_record_found": "No record found",
    "loading_text": "Sit back and relax, while we get the data loaded for you...",
    "well_this_is_unexpected": "Well, this is unexpected...",
    "we_are_sorry": "We are sorry...",
    "records": "records",

    "transportation_to_be_planned": "Transportation to be planned",
    "transportation_planning": "Transportation planning",
    "transportation_processing": "Transportation processing",
    "transportation_carrier_committed": "Transportation Carrier committed",
    "checked_in_by_DC": "Checked in by DC",
    "loading_started": "Loading Started",
    "ready_for_pickup": "Ready for Pickup",
    "in_transit": "In Transit",
    "delivery_confirmed": "Delivery confirmed",
    "in_yard": "In Yard",
    "unloading": "Unloading",
    "unloading_completed": "Unloading Completed",

    "material_#": "Material #",
    "total_demand_quantity": "Total Demand Quantity",
    "total_unrestricted_quantity": "Total Unrestricted Quantity",
    "delivery_quantity": "Delivery Quantity",
    "stock_balance": "Stock Balance",
    "shortage_quantity": "Shortage Quantity",
    "actively_tracked_by_fourkites_eta": "Actively tracked by Fourkites eta",
    "trailer": "Trailer",
    "carrier": "Carrier",

    "shipment_#": "Shipment #",
    "destination_eta": "Destination ETA",
    "origin_eta": "Origin ETA",
    "shipment_end_date_&_time": "Shipment end date & time",
    "estimated_unloading_time": "Estimated unloading time (In hours)",
    "yes": "Yes",
    "no": "No",
    "tracking_#": "Tracking #",
    "trailer_#": "Trailer #",
    "yard_arrival_date_&_time": "Yard arrival date & time",
    "unloading_start_date_&_time": "Unloading start date & time",

    "distribution_breadcrumb_text": "Distribution",
    "network_breadcrumb_text": "Network",
    "order_management_breadcrumb_text": "Order Management",
    "transportation_breadcrumb_text": "Transportation",

    "go_to_stock_constraint_report": "Go to stock constraint report",
    "live_status_text": "LIVE STATUS",
    "shipment_created": "Shipment Created",
    "shipment_status": "Shipment Status",

    "order_management_title": "ORDER MANAGEMENT",
    "distribution_title": "DISTRIBUTION",
    "transportation_planning_title": "TRANSPORTATION PLANNING",
    "transportation_execution_title": "TRANSPORTATION EXECUTION",
    "transport_title": "Transport",

    "outbound_order_details": "OUTBOUND ORDER DETAILS",
    "search_helper_text_network_page": "Enter Shipment#, Order#, Delivery #",

    "blocked_tms_planned": "BLOCKED TMS PLANNED",
    "blocked_non_tms_planned": "BLOCKED NON TMS PLANNED",
    "orders_block_free": "ORDERS BLOCK FREE",
    "back_orders": "BACK ORDERS",
    "material_availability_date": "Material Availability Date",
    "ship_outside_2_days": "Ship outside 2 Days",
    "healthy": "Healthy",
    "ship_within_2_days": "Ship within 2 Days",
    "critical_risk": "Critical Risk",
    "potential_risk": "Potential Risk",

    "planning_status": "PLANNING STATUS",
    "shipment_processing": "SHIPMENT PROCESSING",
    "carrier_committed": "Carrier Committed",
    "transportation_execution_status": "TRANSPORTATION EXECUTION STATUS",
    "suspended_status": "SUSPENDED STATUS",
    "appointment_status": "APPOINTMENT STATUS",
    "transportation_details": "Transportation Details",
    "search_helper_text_transportation_page": "Enter Shipment#, Order#, Trailer id, Delivery#",
    "carrier_ready_date": "Carrier Ready Date",

    "business": "Business",
    "atleast_one_business_unit_needs_to_selected": "Atleast one Business Unit needs to be selected",
    "business_unit": "Business Unit",
    "business_units": "Business Units",
    "customer_sales_office": "Customer/Sales Office",
    "results": "Results",
    "filter_by": "Filter By",
    "view_by": "View By",
    "customer": "Customer",
    "sales_org": "Sales Org",
    "search_customer": "SEARCH CUSTOMER",
    "results_found": "results found",
    "clear_selections": "Clear Selections",
    "save_selections": "Save Selections",
    "favourites_message": "Favourited options will appear as default selections every time you visit Logistics MAESTRO. To favourite the customer just click on the fav icon",
    "location": "Location",
    "sales_office_group": "SALES OFFICE/GROUP",
    "you_can_favourite_locations!!": "You can Favourite Locations!!",
    "favourited_message": "Favourited locations will appear as default selected location every time you visit Logistics MAESTRO",
    "favourite_location_message_": "To favourite the locations just click on the fav icon",
    "next_to_the_location_name": "next to the location name.",
    "no_text": "No",
    "found_matching_the_search_term": "found matching the search term",
    "sales_office": "Sales Office",
    "sales_groups": "Sales Groups",
    "list_of_sales_group_for": "List of Sales Group for",
    "search_text": "SEARCH",
    "list_of_sales_offices_for_sales_org": "List of Sales offices for Sales Org",
    "sales_office_customer": "Sales Office / Customer",
    "menu": "Menu",
    "favourites": "Favourites",
    "start_date": "Start Date",
    "end_date": "End Date",

    "100_%_confirmed_cube": "100 % confirmed Cube",
    "less_than_100%_confirmed_cube": "Less than 100% confirmed Cube",
    "visible_in_tms": "Visible In tms",
    "not_visible_in_tms": "Not Visible In tms",
    "pickup_not_scheduled": "Pickup Not Scheduled",
    "pickup_package_multi_block": "Pickup/package multi block",
    "immediate_action": "Immediate Action",

    "delivery_#": "Delivery #",
    "inbound_shipment_status": "Inbound Shipment Status",

    "order_#": "Order #",
    "order_status": "Order Status",
    "order_type": "Order Type",
    "origin_id": "Origin ID",
    "origin": "Origin",
    "dest_city": "Dest City",
    "dest_state": "Dest State",
    "original_rdd": "Original RDD",
    "rdd": "RDD",
    "pickup_appt_date_&_time": "Pickup Appointment Date & Time",
    "ship_condition": "Ship Condition",
    "reason_for_alert": "Reason for Alert",
    "distribution_channel": "Distribution Channel",
    "sales_group": "Sales Group",
    "mad": "MAD",
    "confirmed_delivery_appt_reschedule_count": "#Reschedules",
    "delivery_appt_date_&_time": "Current Delivery Appointment date & time",
    "confirmed_original_delivery_appt_date_time": "Original delivery Appointment date & time",
    "delivered_date_&_time": "Delivered Date & Time",
    "fourkites_status": "Fourkites Status",
    "late_reason_code": "Late Reason Code",
    "actual_arrival_at_origin" : "Actual Arrival At Origin",
    "actual_departure_at_origin" : "Actual Departure At Origin",
    "actual_arrival_at_destination" : "Actual Arrival At Destination",
    "actual_departure_at_destination" : "Actual Departure At Destination",

    "sl_no": "Sl.No. ",
    "material_id": "Material ID",
    "customer_material_id": "Customer Material ID",
    "material_description": "Material Description",
    "material_type": "Material Type",
    "ordered_qty": "Ordered Qty",
    "confirmed_qty": "Confirmed Qty",
    "load_qty": "Load Qty",
    "difference_qty": "Difference Qty",
    "delivery_block": "Delivery Block",
    "product_details": "Product Details",
    "location_performance": "LOCATION PERFORMANCE",
    "mill_dc_name": "Mill/DC Name",
    "status": "Status",
    "net_loading_hours": "Net Loading Hours (Behind/Ahead)",
    "no_data_found": "No Data Found",
    "monitor": "Monitor",
    "behind": "Behind",
    "critical": "Critical",
    "no_data": "No Data",
    "performance_map_title": "SITE PERFORMANCE MAP – OUTBOUND LOADING SCHEDULE (HOURS AHEAD/BEHIND)",
    "location_search": "Location Search",
    "refresh": "Refresh",
    "map_view": "Map View",
    "table_view": "Table View",
    "filters": "Filters",
    "map_err_msg": "Sorry, something went wrong. Please try again, or try with different browser",
    "map_loading_msg": "Map is Loading...",
    "ahead_more_than_3_hours": "(Ahead >3 hours)",
    "ahead_below_3_hours": "(Ahead 0.1-3 hours)",
    "behind_less_than_6_hours": "(Behind >0 to 6 hours)",
    "behind_more_than_6_hours": "(Behind >6 hours)",
    "no_data_0_hour": "(No Data 0 hour)",
    "filter_search": "FILTERS SEARCH",
    "clear_all": "Clear All",
    "select_all": "Select All",
    "enter": "Enter",
    "expand": "Expand",
    "collapse": "Collapse",
    "all": "All",
    "enter_a_view_name": "Enter a view name",
    "saved_successfully": "saved successfully",
    "untitled_view": "Untitled View",
    "created": "created",
    "deleted": "deleted",
    "views": "Views",
    "new_view_name_msg": "Creates a new view with default settings",
    "add_view": "Add View",
    "reset": "Reset",
    "new_view": "New View",
    "save": "Save",
    "delete": "Delete",
    "all_dc_and_mill": "All DC and Mills",
    "dc": "DC",
    "mill": "MILL",

    //Filters - Network
    "destination_state": "Destination State",
    "destination_city": "Destination City",
    "carrier_service_code": "Carrier Service Code",
    "exclude_carrier_service_code": "Exclude Carrier Service Code",
    "requested_delivery_date": "Requested Delivery Date",
    "delivered_date_time": "Delivered Date/Time",
    "customer_confirmed_delivery_date": "Customer Confirmed Delivery Date",
    "unhealthy": "Unhealthy",
    "needs_attention": "Needs Attention",
    "order_block_free": "Order Block Free",
    "order_blocked": "Orders Blocked",
    "manage_columns": "Manage Columns",
    "download_successful": "Download Successful!",
    "download_failed_msg": "Sorry! File Download Failed. Please try again.",
    "network_table": "Network_Table",
    "order_management": "Order_Management",
    "transportation": "Transportation",
    "distribution_inbound": "Distribution_Inbound",
    "distribution_outbound": "Distribution_Outbound",
    "min": "Min",
    "max": "Max",
    "reschedules": "Reschedules",


    //Column - Order Management
    "customer_po_#": "Customer PO #",

    "promotionalOrder": "Promotional Order",
    "collectiveNumber": "Collective Number",

    "leq": "LEQ",
    "confirmed_leq": "Confirmed LEQ",
    "allocated_leq": "Allocated LEQ",
    "order_sub_status": "Order Sub Status",

    //Filters - Order Management
    "delivery_credit_block": "Delivery/Credit Block",
    "enter_delivery_block_code": "Enter delivery block code",
    "show_only_credit_hold_orders": "Show Only Credit Hold Orders",
    "confirmed_cube": "Confirmed Cube",
    "100%_confirmed_cube": "100% Confirmed Cube",
    "back_orders_block_free": "Back Orders Block Free",
    "back_orders_blocked": "Back Orders Blocked",
    "all_customer_orders": "All Customer Orders",
    "document_type": "Document type",
    "standard_orders": "Standard Orders (ZSTD)",
    "multi_plant_orders": "Multi Plant Orders (ZMPF)",
    "vmi_orders": "VMI Orders (ZVMI)",
    "merged_orders": "Merged Orders (ZMER)",
    "foc_orders": "FOC Orders (ZFOC)",
    "non_finished_gds": "Non - Finished Gds (ZNFG)",
    "third_party": "Third Party (Z3PO)",
    "dot_com_order": "Dot COM Order (ZCOM)",
    "foc_mult_plant": "FOC Mult Plant (ZFOM)",
    "replacement_ord": "Replacement Ord (ZREP)",
    "scrap_sales": "Scrap Sales (ZSEC)",
    "standard_order": "Standard Order (ZOR)",
    "foc_non_finished_zrfn": "FOC Non - Finished (ZRFN)",
    "foc_non_finished_zfnf": "FOC Non - Finished (ZFNF)",
    "fg_salvage": "FG Salvage (ZFGS)",
    "otc_intraco_sto_apo": "OTC IntraCo STO - APO (ZUB)",
    "otc_interco_sto_apo": "OTC InterCo STO - APO (ZNB)",
    "two_intraco_sto_ph": "TWO IntraCo STO - PH (ZPH)",
    "otc_intraco_sto_manl": "OTC IntraCo STO - Manl (ZUM)",
    "otc_interco_sto_manl": "OTC InterCo STO - Manl (ZNM)",
    "otc_intraco_sto_imprt": "OTC IntraCo STO - Imprt (ZUI)",
    "otc_interco_sto_imprt": "OTC InterCo STO - Imprt (ZNI)",
    "otc_intraco_sto_spcmk": "OTC IntraCo STO - SpcMk (ZUSM)",
    "otc_intercosto_spcmk": "OTC InterCoSTO - SpcMk (ZNSM)",
    "standard_po": "Standard PO (NB)",
    "stock_transport_order": "Stock Transport Order (UB)",
    "shipping_condition": "Shipping Condition",

    //Filters - Transport
    "tm_operational_status": "TM Operational Status",
    "unassigned": "Unassigned",
    "open": "Open",
    "planned": "Planned",
    "tendered": "Tendered",
    "tender_rejected": "Tender Rejected",
    "tender_accepted": "Tender Accepted",
    "confirming": "Confirming",
    "completed": "Completed",
    "appointment_type": "Appointment Type",
    "manual": "Manual",
    "automated": "Automated",
    "appointment_required": "Appointment Required",
    "notified": "Notified",
    "suggested": "Suggested",
    "confirmed": "Confirmed",
    "not_yet_contacted": "Not yet contacted",
    "appt_not_made_by_transport": "Appt not made by Transport",
    "live_load": "Live Load",
    "equipment_type": "Equipment Type",
    "full_truck_load": "Full Truck Load",
    "less_than_truck_load": "Less Than Truck Load",
    "truck_load_tl": "Truck Load (TL)",
    "intermodal_tf": "Intermodal (TF)",
    "open_op": "Open (OP)",
    "package_pg": "Package (PG)",
    "customer_pickup_pu": "Customer Pickup (PU)",
    "order_on_hold": "Order On Hold",
    "delivery_date": "Delivery Date",
    "loading_completed_on_time": "Loading Completed on Time",

    //Column - Transport
    "tm_status": "TM Status",
    "appt_type": "Appt Type",
    "appt_req_d": "Appt Req\'d",
    "appt_status": "Appt Status",
    "short_lead": "Short Lead",
    "loading_completion_date_time": "Loading Completion Date/Time",
    "hours_in_ready_for_pickup": "Hours in Ready for Pickup",
    "load_#": "Load #",

    //Column - Distribution Inbound
    "dest": "Dest",
    "date_and_time_arrived_in_yard": "Date and Time arrived in Yard",
    "duration_in_yard": "Duration in Yard",

    //Filters - Distribution Inbound
    "less_than_24_hours": "< 24 Hours",
    "between_24_and_48_hours": "24 - 48 Hours",
    "greater_than_72_hours": "> 72 Hours",
    "date_arrived_in_yard": "Date Arrived in Yard",

    //Filters - Distribution Outbound
    "load_start_date": "Load Start Date",

    //Column - Distribution Outbound
    "estd_load_hrs_rem": "Estd Load Hrs Rem",
    "total_load_hrs": "Total Load Hrs",

    //Transport Details
    "trailer_status": "Trailer Status",
    "percent_Of_trailer_loaded": "% Of Trailer Loaded",
    "customer_notified": "Customer Notified",
    "delivery_appt_confirmed_by_customer": "Delivery Appt Confirmed by Customer",
    "order_created_in_tms": "Order Created in TMS",
    "customer_appt_time": "CUSTOMER APPOINTMENT TIME",

    //Chart
    "stock_transfer_orders": "Stock Transfer Orders",
    "total": "Total",
    "blocked": "Blocked",
    "blocked_free": "Blocked Free",
    "view_details": "View Details",

    //Reason Code
    "order_blocked_and_shipping_soon": "Order blocked and shipping soon",
    "order_blocked_and_ship_date_past_due": "Order blocked and ship date past due",
    "pickup_not_scheduled_and_shipping_soon": "Pickup not scheduled and shipping soon",
    "pickup_not_scheduled_and_ship_date_past_due": "Pickup not scheduled and ship date past due",
    "order_blocked_and_immediate_action_needed": "Order blocked and Immediate action needed",
    "greater_than_1000_confirmed_cube_&_shipping_soon": "< 1,000 confirmed cube & shipping soon",
    "greater_than_1000_confirmed_cube_&_ship_date_past_due": "< 1,000 confirmed cube & ship date past due",
    "zero_confirmed_cube_&_shipping_soon": "Zero confirmed cube & shipping soon",
    "zero_confirmed_cube_&_ship_date_past_due": "Zero confirmed cube & ship date past due",
    "not_100%_confirmed": "Not 100% confirmed",
    "short_lead_time": "Short Lead-time",
    "can_put_more_product_on_trailer": "Can put more product on trailer",
    "load_too_heavy": "Load too heavy",
    "order_too_big_cannot_fit_on_trailer": "Order too big - cannot fit on trailer",
    "past_due_for_planning": "Past due for Planning",
    "short_lead_or_no_customer_appointment": "Short Lead or no Customer Appointment",
    "not_on_track_to_meet_standard_leadtime": "Not on track to meet standard leadtime",
    "shipping_today_or_in_the_past_and_carrier_not_confirmed": "Shipping today or in the past and carrier not confirmed",
    "shipping_soon_and_carrier_not_confirmed": "Shipping soon and carrier not confirmed",
    "delivery_document_not_created_and_shipping_today_or_in_the_past": "Delivery document not created and shipping today or in the past",
    "delivery_document_not_created_and_shipping_soon": "Delivery document not created and shipping soon",
    "past_due_load_not_picked_up": "Past due - Load not picked up",
    "past_due_delivery_not_confirmed": "Past due - Delivery not confirmed",
    "shipment_not_started_and_LRTC_is_within_12_hours": "Shipment not started and LRTC is within 12 hours",
    "not_on_track_to_achieve_LRTC": "Not on track to achieve LRTC",
    "past_due_late_for_arrival_time": "Past due - late for arrival time",
    "in_yard_more_than_72_hrs": "In Yard more than 72 hrs",
    "unloading_taking_more_than_24_hrs": "Unloading taking more than 24 hrs",

    //Download Table
    "export": "Export",
    "export_view": "EXPORT VIEW",
    "with_product_details": "WITH PRODUCT DETAILS",
    "without_product_details": "WITHOUT PRODUCT DETAILS",

    //Header
    "region": "Region",
    "customers_sales_office": "Customers / Sales Office",
    "show_all": "Show All",
    "hide_all": "Hide All",

    //Legend
    "consumer": "Consumer",
    "professional": "Professional",
    "above_guard_rail": "Above Guard Rail",
    "below_guard_rail": "Below Guard Rail",

    //Shipment details
    "shipment_load_number": "Shipment/Load Number",
    "latest_location": "Latest Location",
    "destination": "Destination",
    "mode": "Mode",
    "stops": "Stops",
    "tracking_details": "Tracking Details",
    "planned_arrival": "Planned Arrival",
    "appointment_time": "Appointment Time",
    "detected_near_facility": "Detected Near Facility",
    "departed_facility": "Departed Facility",

    //TabsBar
    "network_view": "Network View",
    "my_selections": "My Selections",
    "view_more": "View More",
    "view_less": "View Less",

    "you_do_not_have_any_favourites": "You do not have any favourites.",
    "list_of_favourited_selections": "List of Favourited Selections",

    //AppDrawer
    "home": "Home",
    "glossary": "Glossary",
    "close_menu": "Close Menu",
    "supply_chain_analytics": "Supply Chain Analytics",

    "last_updated": "Last updated",
    "feedback": "Feedback",
    "feedback_sucessfully_posted": "Feedback Sucessfully Posted!",
    "something_went_wrong_please_try_again": "Something Went Wrong!, Please try again",
    "send_us_feedback": "Send Us Feedback",
    "thank_you_for_taking_the_time_to_give_us_feedback": "Thank you for taking the time to give us feedback.",
    "are_you_satisfied_with_your_experience": "Are you satisfied with your experience ?",
    "required": "Required",
    "submit_feedback": "Submit Feedback",
    "hide": "Hide",
    "show": "Show",
    "site_type": "Site Type",
    "domestic": "Domestic",
    "international": "International",
    "access_sales_group_by_clicking_on_the_sales_office": "Access Sales Group by clicking on the Sales Office",
    "found": "Found",
    "to_be_planned": "To be planned",
    "planning": "Planning",
    "something_went_wrong": "Something Went Wrong",
    "please_wait": "Please Wait...",
    "location_volume_per_day": "LOCATION VOLUME PER DAY",
    "in_hours": "IN HOURS",
    "in_truck_loads": "IN TRUCK LOADS",
    "edit_update_guardrails": "Edit/Update Guardrails",
    "guardrail_date": "Guardrail Date",
    "unable_to_load": "Unable to Load",
    "time_zone": "TIME ZONE",
    "select_language": "SELECT LANGUAGE",

    "search_helper_text_order_page": "Enter Shipment#, Order#, Customer PO#, Delivery #",
    "origin_sites": "Origin Sites",
    "view": "View",
    "more": "more",
    "less": "less",
    "loads": "Loads",
    "orders": "Orders",
    "unit": "Unit",
    "hours": "Hours",
    "hour": "Hour",
    "update_changes": "Update Changes",
    "distribution_guardrails": "Distribution Guardrails",

    "download_started_msg": "File Download started. This might take a couple of seconds.",
    "download_progress_msg": "File download is already in progress",
    "rows_per_page": "Rows per page",
    "no_records_to_display": "No records to display",
    "track_trace": "Track & Trace",
    "with_managed_columns_and_filters_applied": "With managed columns and filters applied",
    "pickup": "Pickup",
    "delivery": "Delivery",
    "just_now": "just now",
    "promotional_material": "Promotional Material",
    "indicates_promotional_orders": "Indicates promotional orders",
    "tell_us_about_your_experience": "Tell us about your experience",
    "and": "and",
    "show_tabs_by": "Show tabs by",
    "monday": "Monday",
    "tuesday": "Tuesday",
    "wednesday": "Wednesday",
    "thursday": "Thursday",
    "friday": "Friday",
    "saturday": "Saturday",
    "sunday": "Sunday",
    "of": "of",
    "health": "Health",
    "show_only_promotional_orders": "Show Only Promotional Orders",
    "hide_promotional_orders": "Hide Promotional Orders",
    "above_current_guard_rail": "Above Current Guard Rail",
    "below_current_guard_rail": "Below Current Guard Rail",
    "tutorials_&_resources": "Tutorials & Resources",
    "fourkites_back_button": "Back to search results",


    //Guadrail Page
    "accept": "Accept",
    "decline": "Decline",
    "enter_reason_here": "Enter reason here",
    "close": "Close",
    "name": "Name",
    "reason":"Reason",
    "please_mention_reason_for_decline":"Please mention the reason for declining the access to edit/update guardrails",
    "your_request_submitted_wait_for_approval": "Your request has been submitted. Please wait for the appproval. You will be notified via email.",
    "request_access": "Request Access",
    "please_mention_reason_for_request": "Please mention why you need access to edit/update guardrails*",
    "edit_update_access_req_for_guardrail": "Edit/update access Request for Guardrail chart", 
    "pending_requests": "Pending requests",
    "no_pending_requests": "No Pending Requests",

    // Transport - Item details table
    "actual_departure_from_origin": "Actual Departure from Origin",
    "actual_departure_from_destnation": "Actual Departure from Destnation",
    "yms": "YMS",
    "sap": "SAP",
    "fourkites": "Fourkites",
    "tms": "TMS",

    // OSM
    "country": "Country",
    "kpis": "KPIS",
    "shipment_freight_order": "Shipment/Frt Ord",
    "sap_shipment_status": "SAP Shpmt Status",
    "stage": "Stage",
    "ship_to_region": "Ship-To Region",
    "tariff_service_code": "Tariff Service Code",
    "scac": "SCAC",
    "seal_number": "Seal Number",
    "seal_no": "Seal No.",
    "carrier_name": "Carrier Name",
    "trailer_number": "Trailer No.",
    "pro_#": "Pro #",
    "bol": "Bill of Lading",
    "shipment_created_date_time": "Shipment Created Date & Time",
    "actual_shipmemt_start_date_time": "Actual Shipment Start Date & Time",
    "actual_load_end_date_time": "Actual Load End Date & Time",
    "load_diagram_status": "Load Diagram Status",
    "ld_status": "LD Sta",
    "wms_status": "WMS Sta",
    "edi_status": "EDI Status",
    "tender_status": "Tender Status",
    "manifest_status": "Manifest Status",
    "freight_lifecycle_status": "Freight Life Cycle Status",
    "fo_planning_status": "FO Planning Status",
    "fo_execution_status": "FO Execution Status",
    "ucl_label_required": "UCL Label Required",
    "ucl_required": "UCL Rqd",
    "ucl_label_printed": "UCL Label Printed",
    "ucc_label_printed": "UCL Label Printed",
    "ucl_printed": "UCL Prt",
    "addr_label_required": "ADDR Label Required",
    "addr_required": "ADDR Rqd",
    "addr_label_printed": "ADDR Label Printed",
    "addr_printed": "ADDR Prt",
    "rfid": "RFID",
    "shipmemt_created_by": "Shipment Created By",
    "shipmemt_last_changed_by": "Shipment Last Changed By",
    "warehouse_ready_date_time": "Warehouse Ready Date & Time",
    "wh_ready_date_time": "WHReady Date & Time",
    "case_label_print_status": "Case Label Print Status",
    "tms_load_number": "TMS Load Number",
    "tms_load_appt_status": "TMS Load Appt Status",
    "tms_load_appt_status_text": "TMS Load Appt Status Text",
    "freight_order_status": "Freight Order Status",
    "order_num": "Order Number",
    "delivery_num": "Delivery Number",
    "sales_doc_type": "Sales Doc Type",
    "ship_rec_point" : "Ship/Rec Point",
    "route": "Route",
    "ship_to_name": "Ship-To Name",
    "ship_to_city": "Ship-To City",
    "ship_to_postalcode": "Ship-To Postal Code",
    "sold_to": "Sold-To",
    "sold_to_name": "Sold-To Name",
    "carrier_ready_date_time": "Carrier Ready Date & Time",
    "estm_loading_hours": "Estm Loading Hours",
    "direct_load_indicator": "Direct Load Indicator",
    "rank_segmentation": "Rank Segmentation",
    "leq_cb": "LEQ CB",
    "po_type": "PO Type",
    "order_header_db": "Order Header DB",
    "header_billing_block": "Header Billing Block",
    "payment_terms": "Payment Terms",
    "incoterms_one": "Incoterms 1",
    "incoterms_two": "Incoterms 2",
    "credit_status": "Credit Status",
    "credit_status_light": "Credit Status Light",
    "goods_issue_status": "Goods Issue Status",
    "sto_receipt_status": "STO Receipt Status",
    "credtord_create_time_ord": "CredtOrd & Create-Time Ord",
    "credtdel_create_time_dlv": "CredtDel & Create-Time Dlv",
    "priority": "Priority",
    "spec_proc": "Spec Proc",
    "coll_key": "Coll Key",
    "appt_confirm": "Appt Conf",
    "appt_contact": "Appt Contact",
    "appt_num": "Appt Number",
    "condition_group_ship_with": "Cond Grp-Ship With",
    "condition_group_sequence": "Cond Grp-Sequence",
    "actual_gi_date": "Actual GI Date",
    "total_gm_status": "Total GM Status",
    "cust_serv_rep": "Cust Serv Rep",
    "csr_name": "CSR Name",
    "order_created_by": "Order Created By",
    "order_created_by_name": "Ord Created By Name",
    "dlv_created_by": "Dlv Created By",
    "dlv_created_by_name": "Dlv Created By Name",
    "dlv_last_changed_by": "Dlv Last Changed By",
    "dlv_last_changed_by_name": "Dlv Last Changed By Name",
    "i2_flag": "I2 Flag",
    "fully_picked": "Fully Picked?",
    "z856_asn": "Z856 ASN",
    "hdr_console_relevant": "HDR.Consol. Relevant",
    "hdr_descre_relevant": "HDR.Discre. Relevant",
    "hdr_parcel_label_print_status": "HDR.Parcel Lbl Prnt Stat",
    "delivery_db": "Delivery DB",
    "confirmed_estm_load_hrs": "Est Ldg Hr",
    "hdr_reject_status": "HDR Reject Status",
    "hdr_ord_blng_status": "HDR Ord Blng Status",
    "order_delivery_details": "Order/Delivery Details",
    "material_group_num": "Material Group #",
    "live": "Live",
    "equip_type": "Equip Sz",
    "fin_unfin_goods": "Fin/Unfin Goods",
    "haz_cd": "Haz.Cd.",
    "ld_strt_date_time": "LdStrt Date & Time",
    "submit_ld": "Submit LD",
    "send_to_wms": "Send To WMS",
    "print_ucl_label": "Print UCL Label",
    "print_addr_label": "Print Addr Label",
    "reprint_lsr": "Reprint LSR",
    "submit_ld_simulation": "Submit LD Simulation",
    "warehouse_ready_date": "Warehouse Ready Date",
    "load_diagram_submission": "Load Diagram Submission",
    "loading_instructions": "Loading Instructions",
    "diagram_type": "Diagram Type",
    "trailer_dimensions": "Trailer Dimensions",
    "trailer_dimension_error": "We were unable to retreive the vehicles and its dimensions at this moment.",
    "vehicle": "Vehicle",
    "measurement_unit": "Measurement Unit",
    "length": "Length",
    "frontHeight": "Height",
    "width": "Width",
    "internal_material_handling_comments": "Internal Material Handling Comments",
    "comments": "Comments",
    "no_material_handling_comments_found": "No Material Handling Comments Found",
    "delivery_comment_error": "We were unable to retreive the comments at this moment.",
    "printing_options": "Printing Options",
    "report_destination": "Report Destination",
    "lsr_copies": "LSR Copies",
    "add_another_row": "Add Another Row",
    "finished_goods": "Finished Goods",
    "calculation_missing": "Calculation Missing",
    "compl_confirm_mising": "Compl Confirm Missing",
    "prices_incomplete": "Prices Incomplete",
    "check_terms_of_payment": "Check Terms of Payment",
    "check_terms_of_dlv": "Check Terms of Dlv",
    "check_credit_memo": "Check Credit Memo",
    "check_debit_memo": "Check Debit Memo",
    "refrb_price_check": "Refrb Price Check",
    "denied_party": "Denied Party",
    "denied_credit": "Denied Credit",
    "missing_freight_fee": "Missing Freight Fee",
    "military_price_diff": "Military Price Diff",
    "not_relevant": "Not Relevant",
    "not_yet_processed": "Not Yet Processed",
    "partially_processed": "Partially Processed",
    "completely_processed": "Completely Processed",
    "shipment_freight_order_status": "Shipment/Freight Order Status",
    "not_submitted": "Not Submitted",
    "successful": "Successful",
    "not_successful": "Not Successful",
    "red_praire_wms_status": "Red Praire/WMS Status",
    "header_reject_status": "Header Reject Status",
    "header_order_related_billing_status": "Header Order Related Billing Status",
    "not_planned": "Not Planned",
    "printed": "Printed",
    "not_printed": "Not Printed",
    "freight_order_planning_status": "Freight Order Planning Status",
    "freight_order_execution_status": "Freight Order Execution Status",
    "in_execution": "In Execution",
    "not_started": "Not Started",
    "executed": "Executed",
    "shipping_point": "Shipping Point",
    "receiving_point": "Receiving Point",
    "show_filters": "Show Filters",
    "hide_filters": "Hide Filters",
    "carrier_scac": "Carrier (SCAC)",
    "no_vehicles_found": "No Vehicles Found",
    "load_diagram_submitted_successfully": "Load Diagram Submitted Successfully",
    "load_diagram_submission_failed": "Load Diagram Submission Failed. Please see the LD Submission Logs.",
    "sent_to_wms_successfully": "Sent To WMS Successfully",
    "sales_document_type": "Sales Doc.Type",
    "please_wait_while_the_data_is_loading": "Please wait while the data is loading...",
    "shipment_num_freight_order": "Shipment/Freight Order",
    "compartmentID": "Compartment",
    "ship_to": "Ship-To",
    "send_order_to_i2_consumer": "Send Order to I2 (Consumer)",
    "send_delivery_to_i2_hc": "Send Delivery to I2 (HC)",
    "please_enter_valid_printer_name": "Please Enter a Valid Printer Name.",
    "no_view_error_message":"Atleast one tab should be made visible to view the content",
    "osm_breadcrumb_text": "DOCC",
    "new_view_tab_msg": "Creates a new view tab with default settings",
    "add_tab": "Add Tab",
    "enter_printer_name": "Enter printer name",
    "use_if_a_hard_copy_of_lsr_is_required": "Use if a hard copy of LSR is required",
    "select_vehicle": "Select Vehicle",
    "invalid_shipment_error_text": "Load diagram has already been submitted for this shipment. Delete LD event in SAP and reset to Planning Status to re-run.",
    "seal_empty_error_text": "Please update seal number in ECC/S4, refresh the table and retry after some time.",
    "ld_precheck_error_text": "Failed to process your request for Submit LD. Retry after some time.",
    "credit_check_not_executed": "Credit check was not executed/Status not set",
    "credit_check_was_executed_doc_ok": "Credit check was executed, document OK",
    "credit_check_was_executed_doc_not_ok": "Credit check was executed, document not OK",
    "credit_check_was_executed_doc_not_ok_partial": "Credit check was executed, document not OK, partial release",
    "doc_released_by_credit_representative": "Document released by credit representative",
    "not_processed": "Not Processed",
    "80_domestic": "80 - Domestic",
    "90_international": "90 - International",
    "2810_us_consumer": "2810 - US Consumer",
    "2811_us_professional": "2811 - US Professional",
    "2820_canada_consumer": "2820 - Canada Consumer",
    "2821_canada_professional": "2821 - Canada Professional",
    "load_diagram_submission_pending": "Load Diagram Submission Pending",
    "shipment_start": "Shipment Start",
    "shipment_end": "Shipment End",
    "shipment_completion": "Shipment Completion",
    "ld_pending": "LD Pending",
    "ld_submitted": "LD Submitted",
    "print_ucl": "Print UCL",
    "download_to_wms": "Download to WMS",
    "shipment_compl": "Shipment Compl.",
    "load_diagram_submitted": "Load Diagram Submitted",
    "downloaded_to_wms": "Download to WMS",
    "loading_ended": "Loading Ended",
    "address_label_printed": "Address Label Printed",
    "LSR_copy": "LSR Copy",
    "ucl_copy": "UCL Copy",
    "addr_copy": "Addr Copy",
    "print_lsr": "Print LSR",
    "more_lsr_Error": "Please print either 1 or 2 LSR copies ",
    "more_addr_error": "Please enter a number from 1-50 to print ADDR copies",
    "reprint_lsr_successful": "Reprint LSR Successful",
    "printer_name_is_not_valid": "Printer name is not valid",
    "shipment_is_not_valid_for_reprint": "Shipment is not valid for reprint LSR",
    "load_diagram_not_submitted": "Load Diagram is not submitted for this shipment",
    "duplicate_view_error": "View Name Already Exists, Please choose a different name.",
    "delete_view_error": "You are about to delete this View \"{{name}}\". Are you sure?",
    "view_lsr_for_shipment_num": "View Load Sequence Report for shipment number",
    "back_to_docc": "Back to DOCC",
    "back_to_docc_results": "Back to DOCC Search Results",
    "run_date_time": "Run Date & Time",
    "submitted_from": "Submitted From",
    "tariff": "Tariff",
    "vehicle_id": "Vehicle ID",
    "ship_method": "Ship Method",
    "latest_start_date_time": "Latest Start Date & Time",
    "stop_related_info": "Stop Related Information",
    "load_sequence": "Load Sequence",
    "customer_po": "Customer PO",
    "load_codes": "Load Codes",
    "pallet_info": "Pallet Information",
    "material_handling_comments": "Material Handling Comments",
    "ship_weight_info": "Ship Weight Information",
    "net_weight": "Net Weight",
    "steer_axle_weight": "Steer Axle Weight",
    "drive_axle_weight": "Drive Axle Weight",
    "rear_axle_min_max_setting": "Rear Axle Min/Max Setting",
    "rear_axle_min_max_weight": "Rear Axle Min/Max Weight",
    "remaining_gap_left": "Remaining Gap Left",
    "remaining_gap_right": "Remaining Gap Right",
    "ship_totals": "Shipment Totals",
    "shipped_qty": "Shipped Qty",
    "difference": "Difference",
    "beginning_cube": "Beginning Cube",
    "ending_cube": "Ending Cube",
    "adjusted_material_info": "Adjusted Material Information",
    "adjust_code": "Adjust Code",
    "qty_ordered": "Qty Ordered",
    "qty_shipped": "Qty Shipped",
    "cases_cut": "Cases Cut",
    "max_add": "Max Add",
    "max_cut": "Max Cut",
    "packaging_material": "Packaging Material",
    "qty_difference": "Qty Difference",
    "loose_pallets": "Loose Pallets",
    "loose_slip_sheet": "Loose Slip-Sheet",
    "stack_details": "Stack Details",
    "left": "Left",
    "right": "Right",
    "stack": "Stack",
    "load_seq": "Load SEQ",
    "order_qty": "Order Qty",
    "product_code": "Product Code",
    "placement": "Placement",
    "osm_docc_abbr": "DOCC/Distribution Operations Command Center",
    "back_to_top": "Back to Top",
    "view_stack_details_and_lsr": "View Stack Details & LSR",
    "view_stack_details": "View Stack Details",
    "view_lsr": "View LSR",
    "stop": "Stop",
    "no_info_found": "No Information Found",
    "view_stack_details_for_shipment_num": "View Stack Details for Shipment Number",
    "general_control": "General Control",
    "stop_control": "Stop Control",
    "stack_header": "Stack Header",
    "stack_detail": "Stack Detail",
    "stack_error": "Stack Error",
    "ld_submission_log": "LD Submission Log",
    "source_type": "Source Type",
    "routine_type": "Routine Type",
    "load_type": "Load Type",
    "load_instructions": "Load Intructions",
    "stock_code_summary_id": "Stock Code Summary ID",
    "printer_id": "Printer ID",
    "num_of_copies_lsr": "Number of Copies (LSR)",
    "package_lsr_report_ind": "Package LSR Report Indicator",
    "mainframe_jobname": "Mainframe Jobname",
    "error_code": "Error Code",
    "standard_indicator": "Standard Indicator",
    "volume_unit": "Volume Unit",
    "max_cases_add": "Max Cases Add",
    "max_cases_cut": "Max Cases Cut",
    "ucl_labelling": "UCL Labelling",
    "simulate_indicator": "Simulate Indicator",
    "pack_instruction_ind": "Pack Instruction Indicator",
    "actual_pick_qty_ind": "Actual Pick Qty Indicator",
    "weight_unit": "Weight Unit",
    "net_weight_of_trailer_one": "Net Weight Of Trailer 1",
    "net_weight_of_trailer_two": "Net Weight Of Trailer 2",
    "steer_axle_wt_of_trailer_one": "Steer Axle Wt Of Trailer 1",
    "steer_axle_wt_of_trailer_two": "Steer Axle Wt Of Trailer 2",
    "drive_axle_wt_of_trailer_one": "Drive Axle Wt Of Trailer 1",
    "drive_axle_wt_of_trailer_two": "Drive Axle Wt Of Trailer 2",
    "unit_of_dimension_for_lwh" : "Unit Of Dimension For Length/Width/Height",
    "rear_axle_min_distance_setting_trailer_one": "Rear Axle Min Distance Setting - Trailer 1",
    "rear_axle_max_distance_setting_trailer_one": "Rear Axle Max Distance Setting - Trailer 1",
    "rear_axle_min_weight_setting_trailer_one": "Rear Axle Min Weight Setting - Trailer 1",
    "rear_axle_max_weight_setting_trailer_one": "Rear Axle Max Weight Setting - Trailer 1",
    "rear_axle_min_distance_setting_trailer_two": "Rear Axle Min Distance Setting - Trailer 2",
    "rear_axle_max_distance_setting_trailer_two": "Rear Axle Max Distance Setting - Trailer 2",
    "rear_axle_min_weight_setting_trailer_two": "Rear Axle Min Weight Setting - Trailer 2",
    "rear_axle_max_weight_setting_trailer_two": "Rear Axle Max Weight Setting - Trailer 2",
    "container_id": "Container ID",
    "created_on": "Created On",
    "time_created": "Time Created",
    "changed_on": "Changed On",
    "last_changed_at": "Last Changed At",
    "storage_location": "Storage Location",
    "total_order_qty": "Total Order Qty",
    "total_load_qty": "Total Load Qty",
    "center_of_gravity": "Center Of Gravity",
    "new_wms": "New WMS",
    "physical_stop_sequence_num": "Physical Stop Sequence Number",
    "pallet_slipsheet_ind": "Pallet Slipsheet Indicator",
    "packaging_material_delivery_qty": "Packaging Material Delivery Qty",
    "packaging_material_expected_qty": "Packaging Material Expected Qty",
    "packaging_material_actual_qty": "Packaging Material Actual Qty",
    "target_qty_uom": "Target Qty UOM",
    "load_code_one": "Load Code 1",
    "load_code_two": "Load Code 2",
    "load_code_three": "Load Code 3",
    "load_code_four": "Load Code 4",
    "packaging_material_two": "Packaging Material 2",
    "packaging_material_two_delivery_qty": "Packaging Material 2 Delivery Qty",
    "packaging_material_two_expected_qty": "Packaging Material 2 Expected Qty",
    "packaging_material_two_actual_qty": "Packaging Material 2 Actual Qty",
    "multiple_platform_ind": "Multiple Platform Indicator",
    "stack_header_id": "Stack Header ID",
    "wms_rp_sequence_num": "WMS RP Sequence Number",
    "stack_row_id_for_stock": "Stack Row ID For Stock",
    "stack_row_id_for_column": "Stack Row ID For Column",
    "delivery_item": "Delivery Item",
    "material_number": "Material Number",
    "batch_management_req_ind": "Batch Management Requirement Indicator",
    "unit_label_serial_num": "Unit Label Serial Number",
    "ucl_label_print_ind": "UCL Label Print Indicator",
    "cube_code": "Cube Code",
    "stack_type": "Stack Type",
    "stack_type_count": "Stack Type Count",
    "customer_purchase_order_num": "Customer Purchase Order Number",
    "expected_qty": "Expected Qty",
    "load_adjustment_qty": "Load Adjustment Qty",
    "max_delivery_qty_for_stack": "Max Delivery Qty For Stack",
    "adjust_reason_code_originally": "Adjust Reason Code Originally",
    "actual_pick_qty": "Actual Pick Qty",
    "yn_ind_set_when_entire_po_is_cut": "Y/N Indicator set to 'Y' when entire PO is cut",
    "rfid_ind": "RFID Indicator",
    "retail_case_goods_item_ind": "Retail Case Goods Item Indicator",
    "load_equivalent_factor": "Load Equivalent Factor",
    "volume": "Volume",
    "platform_slipsheet_material_id": "Platform / Slip Sheet Material ID",
    "display_unit_on_pallet_ind": "Display Unit On Pallet Indicator",
    "overbox_num": "Overbox Number",
    "message_num": "Message Number",
    "message_type": "Message Type",
    "error_message": "Error Message",
    "line_content": "Line Content",
    "submitted_date_time": "Submitted Date & Time",
    "submitted_by": "Submitted By",
    "ld_submission_status": "LD Submission Status",
    "header_stack_type": "Header Stack Type",
    "placement_value": "Placement Value",
    "num_of_pallets_slipsheets": "No.of Pallets or Slipsheets",
    "num_of_pallets_slipsheets_two": "No.of Pallets or Slipsheets 2",
    "rotate": "Rotate",
    "adjacency_violation_ind": "Adjacency Violation Indicator",
    "height_violation_ind": "Height Violation Indicator",
    "hand_stack_ind": "Hand Stack Indicator",
    "cube_of_stack": "Cube of Stack",
    "trailer_sequence": "Trailer Sequence",
    "stack_length": "Stack Length",
    "stack_width": "Stack Width",
    "stack_height": "Stack Height",
    "stack_weight": "Stack Weight",
    "palletized_sku": "Palletized SKU",
    "trailer_input_error": "Please enter valid number between 0 - 999.99 for the field",
    "enter_number": "Enter Number",
    "submit_ld_loading_text": "Please wait while we get all the required data for Load Diagram Submission...",
    "ucc_label_not_required": "Load diagram submitted, but UCL Label not required",
    "addr_label_not_required": "Load diagram submitted, but ADDR Label not required",
    "print_request_failed": "We could not submit the print request at this moment. Please try again after some time.",
    "print_ucl_label_successful": "Print UCL Label Successful",
    "print_addr_label_successful": "Print Addr Label Successful",
    "per_stack_mods": "Per Stack Mods",
    "one_label_per_case": "One Label Per Case",
    "print_label_options": "Print Label Options *",
    "label_options_error": "Please select one of the options to print the label",
    "request_failed": "We could not process your request at this moment. Please try again after some time.",
    "send_to_wms_failed": "Action Send to WMS failed due to interface issue. Please try again after some time",
    "ucl_label_already_printed": "UCL Label is already printed for this shipment. If you still wish to reprint the UCL Label then please re-enter the details below",
    "addr_label_already_printed": "Addr Label is already printed for this shipment. Do you still want to reprint?",
    "success": "Success",
    "error": "Error",
    "addr_label_not_required_for_this_shipment": "Are you sure if you want to print Addr. Label for this shipment?",
    "inbound_file": "Inbound File",
    "outbound_file": "Outbound File",
    "ld_logs_download_success": "Submission Log file downloaded successfully",
    "ld_logs_download_failed": "Submission Log file download failed. Please try again after some time.",
    "custom_date_filter_in_days": "Custom Date Filter (in Days)",
    "apply": "Apply",
    "from": "From",
    "to": "To",
    "today": "Today",
    "default_height_info": "Default Height will be overwritten due to Customer Height restriction of  \{{height}}\ inches ",
    "pallet": "Pallet",
    "pre_built_pallet": "Pre-Built Pallet",
    "slipsheet": "Slipsheet",
    "floor_loaded": "Floor Loaded",
    "hazardous_goods": "Hazardous Goods",
    "ship_volume_info_of_stack": "Ship Volume Information Of Stack",
    "trailer_stack": "Trailer Stack",
    "pallet_size": "Pallet Size",
    "ideal_volume": "Ideal Volume",
    "actual_volume": "Actual Volume",
    "occupancy": "Occupancy",
    "remaining_gap": "Remaining Gap",
    "compartments": "Compartments",
    "min_max_error_text": "Max value should be greater than or equal to min value",
    "enter_value": "Enter Value",
    "trailer_visual": "Trailer Visual",
    "load_start": "Load Start",
    "load_end": "Load End",
    "ship_comp": "Ship Comp",
    "ship_start": "Ship Start",
    "ship_end": "Ship End",
    "gap": "Gap",
    

    // OSM Errors
    "ld_not_submitted_error": "Load Diagram is not submitted for the shipment \"{{shipmentNum}}\".\n Please go back to the DOCC page and try submitting the Load Diagram for this shipment.",
    "lsr_failed": "We could not find the LSR for the shipment \"{{shipmentNum}}\" at this moment.\n Please go back to the DOCC page, select the shipment again and click on View LSR.\n If it still doesn't help, kindly contact our support.",
    "stack_failed": "We could not find the Stack Information for the shipment \"{{shipmentNum}}\" at this moment.\n Please go back to the DOCC page, select the shipment again and click on View Stack Details.\n If it still doesn't help, kindly contact our support.",
    "osm_sites_failed": "We were unable to process the data related to DOCC at the moment.\n Please try refreshing the page after sometime. If it still doesn't help, kindly contact our support.",
    "osm_restricted": "The content you're trying to view has restricted access.\n If you'd like to request an access, kindly contact our support.",
    "diagram_type_error": "Please submit the Diagram with CSR or FSR Diagram type, the MFS type is still under development",
    "height_restriction_error": "Enter Height less than the allowed height of \{{height}}\ inches",
    "see_ld_submission_log": "See Load Diagram Submission Logs for Error Details.\n Please go back to the DOCC page, select the shipment again and click on View Stack Details.\n If it still doesn't help, kindly contact our support.",

    // EMEA Errors
    "emea_restricted": "The page you’re trying to access is restricted.\n If you'd like to request an access, kindly contact our support.",
    "emea_customer_search_failed": "We were unable to fetch the Customers at this moment. Please retry after sometime.",
    "select_channel_org_err": "Please select the Sales Org and Distribution Channel",
    "emea_get_sales_office_failed": "We were unable to fetch the Sales Office and Sales Groups at this moment. Please retry after sometime.",
    "emea_sites_failed": "We were unable to process the data related to EMEA at the moment.\n Please try refreshing the page after sometime. If it still doesn't help, kindly contact our support.",
    "emea_fav_bu_failed": "Failed to update favorite Business Units. Please retry after sometime.",
    "emea_sites_fav_failed": "Failed to update favorite Locations. Please retry after sometime.",
    "emea_cust_fav_failed": "Failed to update favorite Customers. Please retry after sometime.",
    "emea_so_sg_fav_failed": "Failed to update favorite Sales Office and Groups. Please retry after sometime.",
    "emea_get_allfav_failed": "Failed to fetch all your favorites. Please retry after sometime.",
    "emea_edit_comments_failed": "Failed to update comment in the records. Please retry after sometime.",
    "emea_failed_to_fetch_table_records": "Failed to fetch table records. Please retry later.",
    "emea_api_failed": "Failed to fetch the data. Please retry after sometime.",

    //EMEA  
    "order_count": "Order Count",
    "top_five_customers": "Top Five Customers",
    "order_value_$": "Order Value ($)",
    "order_value_currency": "Order Value ({{currency}})",
    "top_five_materials": "Top Five Materials",
    "reasons_for_returns_by_order": "Reasons for Returns (By Order)",
    "reasons_for_returns_by_quantity": "Return Orders (by quantity)",
    "number_of_packs": "Number of Packs",
    "number_of_cases": "Number of Cases",
    "return_orders_by_value": "Return Orders (By Value)",
    "by_value": "By Value",
    "by_quantity": "By Quantity",
    "by_orders": "By Orders",
    "view_more_by_material_group": "View More By Material Group",
    "view_more_by_entry_method": "View More By Entry Method",
    "daily_order_entry": "Daily Order Entry",
    "order_entry_representations": "Order Entry Representations",
    "cases": "Cases",
    "date": "Date",
    "actual_invoice": "Actual Invoice",
    "expected_invoice": "Expected Invoice",
    "percentage": "Percentage",
    "select_currency": "Select Currency",
    "select_country": "Select Country",
    "select_location": "Select Location",
    "loading": "Loading",
    "live_status_last_90days": "LIVE STATUS (Last 90 days)",
    "total_of": "Total: {{total}} {{currency}}",
    "apply_location_changes": "Apply Location Changes",
    "clear_location_selections": "Clear Location Selections",
    "apply_name_changes": "Apply {{name}} Changes",
    "clear_name_selections": "Clear {{name}} Selections",
    "apply_changes": "Apply Changes",
    "locations": "Locations",
    "sales_document": "Sales Document",
    "sold_to_party": "Sold-To Party",
    "ship_to_party": "Ship-To Party",
    "description": "Description",
    "document_date": "Document Date",
    "line_value": "Line Value",
    "order_value": "Order Value",
    "order_currency": "Order Currency",
    "purchase_order_num": "Purchase Order #",
    "order_reason_code": "Order Reason Code",
    "order_reason_description": "Order Reason Desc",
    "item_reason_code": "Item Reason Code",
    "item_reason_description": "Item Reason Desc",
    "reason_for_rejection": "Reason for Rejection",
    "batch": "Batch",
    "sales_unit": "Sales Unit",
    "division": "Division",
    "sales_rep_code": "Sales Rep Code",
    "sales_rep_name": "Sales Rep Name",
    "item_delivery_status": "Item Delivery Status",
    "header_delivery_status": "Header Delivery Status",
    "goods_issue_date": "Goods Issue Date",
    "processing_time": "Processing Time",
    "processing_time_days": "Processing Time (In Days)",
    "comment_updated_by": "Comment Updated By",
    "emea_edit_comments_success": "Updated Comment successfully. Please refresh the table to view updated values.",
    "order_details": "Order Details",
    "not_blocked": "Not Blocked",
    "search_helper_text_returns_page": "Search for Sales Document #, Purchase Order #",
    "excluded_sold_to_party": "Excluded Sold-To Party",
    "excluded_material": "Excluded Material",
    "excluded_sales_document_type": "Excluded Sales Doc. Type",
    "excluded_order_reason_code": "Excluded Order Reason Code",
    "customer_returns": "Customer Returns",
    "authorized_countries": "Authorized Countries",
    "view_created_updated_success": "View Created/Updated Successfully",
    "view_creation_updation_failed": "View Creation/Updatation Failed",
    "view_deleted_success": "View Deleted Successfully",
    "view_deleted_failed": "View Deletion Failed",
    "view_fav_unfav_success": "View Favorite/Unfavorite Success",
    "view_fav_unfav_failed": "View Favorite/Unfavorite Failed",
    "zre_kc_returns": "ZRE - KC Returns",
    "zrfc_kc_foc_returns": "ZRFC - KC FOC Returns",
    "zrbt_kc_bag_n_tag_return": "ZRBT - KC Bag'N Tag Return",
    "zcrf_kc_credit_dl_failure": "ZCRF - KC Credit Dl Failure",
    "zdrf_kc_debit_dl_failure": "ZDRF - KC Debit Dl Failure",
    "less_than_two_days": "< 2 days",
    "two_to_ten_days": "2 - 10 days",
    "greater_than_2_days": "> 10 days",
    "order_entry": "Order Entry",
    "total_num_of_orders": "Total no. of orders",
    "order_entry_summary_by_customer": "Order Entry Summary (By Customer)",
    "order_entry_by_value": "Order Entry (By Value)",
    "order_QTY_CS": "Order QTY (CS)",
    "order_QTY_UOM": "Order QTY (UOM)",
    "product_hierarchy": "Product Hierarchy",
    "quantity": "Quantity",
    "open_orders": "Open Orders",
    "by_status": "By Status",
    "rejected": "Rejected",
    "invoiced": "Invoiced",
    "not_invoiced": "Not Invoiced (Open)",
    "under_credit_analysis": "Under Credit Analysis",
    "total_invoice": "Total Invoice",
    "goto_invoiced_openorders_gap": "Go to Invoiced, Open Orders and Gap",
    "goto_order_status": "Go to Order Status",
    "deliveries_created_today": "Deliveries Created Today",
    "niv_order_created_today": "NIV Order Created Today",
    "niv_invoices_today": "NIV Invoices Today",
    "order_status_req_del_date_rdd_cs": "Order Status - Req Del. Date RDD - CS/ISV",
    "open_orders_distribution_product_structure_cs_niv": "Open Orders Distribution - Product Structure - CS/NIV",
    "order_status_rdd_in_niv": "Order Status - RDD in NIV",
    "invoice_date": "Invoice Date",
    "order_block_code": "Order Block Code",
    "invoice_value": "Invoice Value",
    "rdd_bucket": "RDD Bucket",
    "actual_and_expected_invoice_date_by_value": "Actual and Expected Invoice Date (By Value)",
    "order_status_req_del_date_rdd_by_value": "Order Status Req Del Date RDD (By Value)",
    "open_orders_distribution_product_structure_by_value": "Open Orders Distribution - Product Structure (By Value)",
    "rdd_miss_one_to_seven_days": "RDD miss 1 - 7 days",
    "rdd_delayed_greater_than_seven_days": "RDD delayed > 7 days",
    "rdd_for_today": "RDD for today",
    "rdd_for_tomorrow": "RDD for tomorrow",
    "for_remaining_month": "For remaining month",
    "invoiced_openand_gap_country_code": "Invoiced, Open and GAP by Country Comp. Code",
    "order_cuts_and_rejections" : "Order Rejections", // Changing for now will add ones cuts comes up - Order Cuts And Rejections
    "open_follow_up": "Open Follow Up",
    "cuts": "Cuts",
    "rejections": "Rejections",
    "order_cuts": "Order Cuts",
    "order_rejections": "Order Rejections",
    "rejected_value": "Rejected Value",
    "top_materials_sku": "Top 5 Materials - SKUs",
    "rejection_reasons": "Rejection Reasons",
    "rejected_orders_by_value":"Rejected Orders (By Value)",
    "invoices": "Invoices",
    "invoice_summary": "Invoice Summary",
    "goto_invoices": "Go to Invoices",
    "invoices_details": "Invoices Details",
    "go_to_invoice_details": "Go to Invoice Details",
    "invoiced_mtd" : "Invoiced MTD",
    "invoiced_today": "Invoiced Today",
    "orders_to_be_delivered": "Orders to be Delivered",
    "invoices_distribution": "Invoices Distribution",
    "product_structure": "Product Structure",
    "invoices_per_day": "Invoices Per day",
    "cases_niv": "Cases / NIV",
    "sales_structure": "Sales Structure",
    "invoicing_information": "Invoicing Information",
    "top_five_invoices_per_plant": "Top 5 Invoices Per Plant - NIV",
    "country_id": "Country ID",
    "top5_customers": "Top 5 Customers",
    "Invoices_by_value" : "Invoice (By Value)",
    "forecast": "Forecast",
    "percent_invoiced_vs_forecast": "% Invoiced vs Forecast",
    "total_open_orders": "Total Open Orders",
    "percent_open_orders_vs_forecast": "% Open Orders vs Forecast",
    "blocked_orders": "Blocked Orders",
    "pending_credit_release": "Pending Credit Release",
    "gap_percent": "Gap Percent",
    "billing_document_type": "Billing Doc.Type",
    "click_here_to_view_report": "Click here to view the full report",
    "customer_service_level_bi_report": "Customer Service Level (BI Report)",
    "customer_service_level": "Customer Service Level",

    //LAO Chart
    "lao_fav_bu_failed": "Business Unit Favorite Failed",
    "lao_fav_fetch_failed": "Favorites Fetch Failed",
    "lao_fav_sites_failed": "Site Favorite Failed",
    "daily": "Daily",
    "weekly": "Weekly",
    "exportVolume": "Export Volume",
    "importVolume": "Import Volume",

    //LAO Table
    "purchase_order_origin": "PO",
    "purchase_order_trade_PO": "PO FTZ#",
    "purchase_order_trade_SO": "SO FTZ#",
    "invoice_num": "Invoice Number",
    "invoicing_date": "Invoicing Date",
    "vendor_number": "Vendor Number",
    "vendor_plant": "Vendor Plant",
    "origin_plant": "Plant (Origin Plant)",
    "free_trade_destination_plant": "Plant FTZ Code",
    "free_trade_destination_description": "Plant FTZ Name",
    "destination_plant": "Customer Code/Plant",
    "destination_description": "Customer Name",
    "origin_port": "Origin Port",
    "international_shipper": "International Shipper",
    "booking_number": "Booking Number",
    "shipment_number": "Export Shipment Number",
    "load_number": "Load Number",
    "delivery_number": "Delivery Number",
    "export_shipment_status": "Export Status",
    "origin_country": "Origin Country",
    "destination_country": "Destination Country",
    "bill_lading": "Bill of Lading",
    "bid_lane_number": "BID Lane Number",
    "exportOrder": "Export Order Details",
    "importOrder": "Import Order Details",
    "import_shipment_number": "Import Shipment Number",
    "vendor_name": "Vendor Name",
    "import_pre_shipment_status": "Pre Shipment Status",
    "import_post_shipment_status": "Post Shipment Status",
    "import_destination_port": "Destination Port Name",
    "import_license_number": "Import License Number",
    "import_license_date": "Import License Date",
    "shipment_mode": "Shipment Mode",
    "import_container_type" : "Container Type",
    "incoterms": "Incoterms",
    "lao_order_details_search_helper_text": "Enter PO#, SO#, Invoice#, Load#, Delivery#.",
    "lao_order_details_search_helper_text_import": "PO#, Invoice#, BL#, Import Shipment Number.",
    "po_item": "PO Item",
    "sku_material": "SKU/Material",
    "po_quantity": "PO Quantity",
    "quantity_packed": "Quantity Packed",
    "volume_packed": "Volume Packed",
    "order_unit": "Order Unit",
    "export_order_details": "Export Order Details",
    "entry_method": "Entry Method",
    "material_hierarchy": "Material Hierarchy",
    "export_shipment_etd_date": "KC ETD",
    "export_shipment_eta_date": "KC ETA",
    "export_kc_documental_cutoff_date": "Documental Cut Off",
    "import_eta_date": "KC ETA",
    "import_etd_date": "KC ETD",    
    "import_id_date": "ID Date",
    "import_declaration": "Import Declaration",
    "import_customs_channel": "Customs Channel",
    "import_free_period_end_date": "Free Period End",
    "import_kc_dock_time": "KC Dock Time",
    "import_req_date_cont_at_plant": "Req Date Cont At Plant",
    "import_time_of_arrival_in_plant": "Time Of Arrival In Plant",
    "import_date_cont_at_plant": "Date Cont At Plant",
    "import_hour_cont_at_plant": "Hour Cont At Plant",
    "import_transhipment_etd": "ETD Transhipment",
    "import_tentative_eta": "Tentative ETA",
    "import_tentative_etd": "Tentative ETD",
    "import_customs_leave": "Customs Leave",
    "import_container_return_date": "Container Return Date",
    "delivery_completion_ind": "Delivery Completion",
    
    //LAO Errors
    "lao_restricted": "The page you’re trying to access is restricted.\n If you'd like to request an access, kindly contact our support.",
    "lao_sites_failed": "We were unable to process the data related to LAO at the moment.\n Please try refreshing the page after sometime. If it still doesn't help, kindly contact our support.",
    "views_loading_failed": "Views Loading Failed",
    "apply_selections": "Apply Selections",

    //LAO Filters
    "vendor_name_plant" : "Vendor Name/Plant (Orgin Plant)",
    "customer_code_plant": "Customer Code/Plant",
    "customer_name": "Customer Name",
    "container_ID": "Container ID",
    "sku_id": "SKU (Material)",
    "delivery_eta": "Delivery ETA",
    "po": "Purchase Order",
    "sto": "Sales Order",
    "exportOrderMobile": "Export",
    "importOrderMobile": "Import",

    "helperTextWithSeparator": "For Multiple Search, only use Semicolon as Separator. Ex: 111;222 Wildcard(*) search is only enabled for Single Search. Ex: 111* or 222*",
    "helperText": "For Multiple Search, Use Separators such as Comma or Space or Semicolon. Ex: 111,222 or 111 222 or 111;222 Wildcard(*) search is only enabled for Single Search. Ex: 111* or 222*"

}